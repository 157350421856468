import React from 'react';
import {Container} from '../Container';
import {Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons'
import './header.scss';

const menu = (setLanguage) =>
    <Menu onClick={(key) => setLanguage(key)}>
        <Menu.Item key="ru">RU</Menu.Item>
        <Menu.Item key="kk">KK</Menu.Item>
        <Menu.Item key="en">EN</Menu.Item>
    </Menu>

export const Header = (props => {
    return (
        <header className="header">
            <Container flex={true}>
                <div
                    style={{
                        position: 'relative',
                        top: -2
                    }}
                >
                    <img
                        alt='logo'
                        width="70"
                        className="logo d-inline-block"
                        src="/kcell-logo.svg"
                    />
                </div>

                <Dropdown overlay={menu(props.setLanguage)} trigger={['click']}>
                    <a href="/" className="ant-dropdown-link"
                       onClick={e => e.preventDefault()}>
                        {props.lang.toUpperCase()} <DownOutlined/>
                    </a>
                </Dropdown>
            </Container>
        </header>
    )
});
