import BaseApi from './BaseApi';
import { QR_RESOURCE_NAME } from '../config/constants';

export default class QrApi extends BaseApi {
  static [`get${QR_RESOURCE_NAME}`](payload) {
    return this.downloadFile(`/esim/qr/generate`, payload);
  }
  static [`send${QR_RESOURCE_NAME}`](payload) {
    return this.post('/esim/qr/send', payload);
  }
}
