import React, {useState, useEffect} from 'react';
import {Alert} from 'antd';
import TEXTS from '../locale/texts';
import {Header} from "./Header/Header";
import {Redirect, Route, Switch} from "react-router-dom";
import EsimQr from "./EsimQr";
import {Container} from "./Container";
import {useHistory, useLocation} from 'react-router-dom'
import {Helmet} from 'react-helmet';

const regExp = new RegExp(/\/ru\/|\/en\/|\/kk\//);

const App = () => {
    const [langValue, setLangValue] = useState('ru');
    const history = useHistory();
    const { pathname } = useLocation();
    useEffect(() => {
        const matchLang = pathname.match(regExp)
        if (matchLang) {
            const lang = matchLang.toString()?.replace(/\//g, '');
            setLangValue(lang);
        }
    }, [langValue, pathname]);

    const setLanguage = (value) => {
        const { key } = value;
        setLangValue(key);
        const code = pathname.match(regExp) ? `/${pathname.replace(regExp, ``)}` : '';
        const URL_REDIRECT = `/${key.toLowerCase()}` + code;
        history.replace(`${URL_REDIRECT}`);
    };
    return (
        <div>
            <Helmet defaultTitle={TEXTS.headerText[langValue]}/>
            <Header
                lang={langValue}
                setLanguage={setLanguage}
            />
            <Switch>
                <Route
                    exact
                    path="/:lang?"
                    render={({ match }) => {
                        const params = match.params;
                        let lang = params?.lang ? params.lang : 'ru';
                        if (lang.match(/ru|kk|en/)) {
                            return (
                                <Container>
                                    <Alert
                                        message={TEXTS.alertNoQr[langValue]}
                                        type="warning"
                                        showIcon
                                        style={{ textAlign: 'left' }}
                                    />
                                </Container>
                            );
                        } else {
                            return <Redirect to="/"/>;
                        }
                    }}
                />
                <Route
                    exact
                    path="/:lang/:code"
                    render={({ match }) => {
                        const lang = match.params.lang;
                        const code = match.params.code;
                        if (code && lang && lang.match(/ru|kk|en/)) {
                            return (
                                <EsimQr
                                    code={code}
                                    lang={langValue}
                                />
                            );
                        } else {
                            return <Redirect to="/"/>;
                        }
                    }}
                />
                <Redirect to="/"/>
            </Switch>
        </div>
    );
};
export default App;
