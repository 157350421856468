import axios from 'axios';
import { API_ORIGIN } from '../config';

// A X I O S  G L O B A L S
// set global config for axios
// auth header and baseURL (prepended to url if it is not absolute)
axios.defaults.baseURL = API_ORIGIN;

export default class BaseApi {
  // need to set token on sso login
  // before any other request
  // BaseApi.setToken = 'big token string';
  static set setToken(token) {
    this.token = token;
  }
  static get(urlPath, cancelToken) {
    return axios({
      method: 'GET',
      url: urlPath,
      headers: this.token ? { Authorization: 'Bearer ' + this.token } : {},
      cancelToken
    });
  }
  static downloadFile(urlPath, data, cancelToken) {
    return axios({
      method: 'POST',
      url: urlPath,
      responseType: 'blob',
      data,
      cancelToken
    });
  }
  static post(urlPath, data, cancelToken) {
    console.log(API_ORIGIN);
    return axios({
      method: 'POST',
      url: urlPath,
      headers: this.token ? { Authorization: 'Bearer ' + this.token } : {},
      data,
      cancelToken
    });
  }
}
