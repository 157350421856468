export class ApiError {
  constructor(rawErr, isAlert = false) {
    this.errorTitle = this.getErrorTitle(rawErr, isAlert);
    this.errorMessage = this.getErrorMessage(rawErr, isAlert);
  }
  getErrorTitle() {
    return 'Не удалось выполнить запрос';
  }
  getErrorMessage(_err) {
    const _errMsg =
      _err.response && _err.response.data
        ? _err.response.data.message ||
          (_err.response.data.errors && _err.response.data.errors[0]) ||
          'Произошла ошибка'
        : 'Сервис в данный момент не доступен';
    return _errMsg.toLowerCase();
  }
}
