import React from 'react';

const style = {
    maxWidth: '480px',
    margin: '0 auto',
    textAlign: 'center',
    padding: '15px'
};

const styleFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

export const Container = ({ children, flex = false }) => {
    const _style = flex
        ? {...style, ...styleFlex}
        : style;
    return(
        <div style={_style}>
            {children}
        </div>
    );
};
