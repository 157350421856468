// window.__env__ is used in deployed to kube projects
// process.env is used during local development
let envSource;

if (process.env.NODE_ENV === 'development') {
  envSource = process.env;
} else {
  envSource = window.__env__;
}
// constants taken from .env.* files
// depending on current environment
export const { REACT_APP_API_ORIGIN: API_ORIGIN, REACT_APP_CONFIG: CONFIG } = envSource;
// disable all console logs for production
if (CONFIG === 'prod') {
  console.log = () => false;
  console.info = () => false;
  console.warn = () => false;
  console.error = () => false;
}
