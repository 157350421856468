import { useState, useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { downloadFile } from '../utils/downloadFile';
import { ApiError } from '../utils/errors';
import { logoutUser } from '../utils';
import QrApi from "../api/QrApi";

// https://reactjs.org/docs/hooks-intro.html
// https://reactjs.org/docs/hooks-custom.html
/**
 * React hook for calling api
 * @param {string} resourceName - the name of the resource (users|customers|profiles)
 * @param {string} action - get|getOneOf|set|update|activate resource
 * @param {string} apiCallParams - arguments to api call
 * @param {boolean} initialIsLoading - whether or not show initial loading state
 * @param {Object} notification - notification { type, text }
 * @param {Function} onSuccess - onSuccess callback function
 * @param {Function} onError - onError callback function
 */

export const useApiCall = ({
  resourceName,
  action,
  initialIsLoading = true,
  notification = null,
  onSuccess = null,
  onError = null
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [error, setError] = useState(false);

  const axiosCancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    // cancelling axios promise
    return () => {
      axiosCancelTokenSource.cancel();
    };
  }, []);

  const callApi = async (apiCallParams) => {
    console.group('_callResource GlobalStateContainer method');
    console.log('resourceName:', resourceName);
    console.log('action:', action);
    !!apiCallParams && console.log('apiCallParams:', apiCallParams);
    console.groupEnd();

    !isLoading && setIsLoading(true);
    setError(null);

    const apiClass = ((_name) => {
      switch (_name) {
        case 'qrcode':
          return QrApi;
        default:
          return null;
      }
    })(resourceName.toLowerCase());

    try {
      // make api call
      const response = await apiClass[`${action}${resourceName}`](
        apiCallParams,
        axiosCancelTokenSource.token
      );
      const responseData = response.data ? response.data : null;

      if (action === 'download') {
        downloadFile(response.data, apiCallParams);
      } else {
        setData(responseData);
      }

      setIsLoading(false);
      // extra callbacks if success
      // such as redirects, notifications etc.
      if (onSuccess) {
        onSuccess(responseData, apiCallParams);
      }

      if (notification) {
        message.success(notification.text, 3);
      }

      return responseData;
    } catch (err) {
      console.log(`ERR: ${resourceName} error`, err);

      setIsLoading(false);
      setData(null);
      setError(new ApiError(err));

      if (axios.isCancel(err)) {
        // REQUEST IS CANCELLED
        console.log(`Request get${resourceName} is canceled`, err.message);
      } else {
        // REQUEST IS NOT OK
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          logoutUser();
        }

        if (onError) {
          onError(err);
        } else {
          if (action !== 'getOneOf' && action !== 'get') {
            message.error(new ApiError(err).message, 7);
          }
        }
      }
    }
  };

  return [{ isLoading, data, error }, callApi];
};
