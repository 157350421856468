import React, {useState, useEffect} from 'react';
import {Typography, Form, Button, Input, Alert, message, Skeleton} from 'antd';
import TEXTS from '../locale/texts';
import {downloadFile} from '../utils/downloadFile';
import {Container} from './Container';
import { useApiCall } from '../hooks/useApiCall';
import { QR_RESOURCE_NAME } from '../config/constants';
import fileReader from "../utils/fileReader";

const { Title } = Typography;

const EsimQr = ({ lang, code }) => {
    const [qrImage, setqrImage] = useState(null);
    const [errorType, setErrorType] = useState('errorGenerateCode');

    const [{ isLoading, error }, callApi ] = useApiCall({
        resourceName: QR_RESOURCE_NAME,
        action: 'get',
        onSuccess: (data) => {
            const url = URL.createObjectURL(data);
            setqrImage(url);
        },
        onError: async (error) => {
            if (error.response) {
                const { data } = error.response;
                const file = await fileReader(data);
                const { message } = JSON.parse(file);
                if (message === 'code-old-or-not-found') {
                    setErrorType('errorLinkToOld')
                } else {
                    setErrorType('errorGenerateCode');
                }
            } else {
                setErrorType('errorGenerateCode');
            }
        }
    });

    const [{ isLoading: isLoadingSubmit }, sendEmail ] = useApiCall({
        resourceName: QR_RESOURCE_NAME,
        action: 'send',
        initialIsLoading: false,
        onSuccess: () => {
            message.success(TEXTS.sendEmailSuccess[lang]);
        },
        onError: () => {
            message.error(TEXTS.sendEmailError[lang]);
        }
    });

    useEffect(() => { callApi({code}); }, [code]);

    const onDownloadQr = () => {
        downloadFile(qrImage);
    };
    const sendToEmail = (values) => {
        const { email } = values
        sendEmail({email, code});
    };
    return (
        <Container flex={false}>
            {
                !error ?
                    <Skeleton active loading={isLoading}>
                        <Title level={3}>{TEXTS.headerText[lang]}</Title>
                        <img alt="qr" style={{ height: 230 }} src={qrImage}/>
                        <p>{TEXTS.textQR[lang]}</p>
                        <p>{TEXTS.textSMS[lang]}</p>
                        <Form onFinish={sendToEmail}>
                            <Form.Item name="email"
                                       style={{ textAlign: 'left' }}
                                       rules={[{ required: true, message: TEXTS.emailIsRequired[lang] },
                                           { type: "email", message: TEXTS.emailIsInvalid[lang] },]}>
                                <Input placeholder="example@domain.com"/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary"
                                        htmlType="submit"
                                        loading={isLoadingSubmit}
                                        disabled={isLoadingSubmit}
                                        style={{ width: '100%' }}>
                                    {TEXTS.buttonTextEmail[lang]}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary"
                                        style={{ width: '100%' }}
                                        onClick={onDownloadQr}>
                                    {TEXTS.buttonTextDownload[lang]}
                                </Button>
                            </Form.Item>
                        </Form>
                        <p>{TEXTS.text[lang]}</p>
                    </Skeleton>
                    :
                    <Alert message={TEXTS[`${errorType}`][lang]}
                           style={{ textAlign: 'left' }}
                           type="error"
                           showIcon/>
            }

        </Container>
    );
};
export default EsimQr;
