export const getValueFromStorage = (key) => {
  return localStorage.getItem(key);
};

export const addToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};

export const hasInStorage = (key) => {
  return !!localStorage.getItem(key);
};

export const populateLocalStorage = (objToPopulate) => {
  Object.keys(objToPopulate).forEach((key) => {
    addToStorage(key, objToPopulate[key]);
  });
};
